var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"name-class"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tên phòng "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tên phòng","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-room","name":"nameRoom","placeholder":"Tên phòng","state":_vm.getElementState(errors)},model:{value:(_vm.dataSendForm.name),callback:function ($$v) {_vm.$set(_vm.dataSendForm, "name", $$v)},expression:"dataSendForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"code-class"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mã phòng "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Mã phòng","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code-class","type":"text","placeholder":"Mã phòng","disabled":_vm.disableCode,"state":_vm.getElementState(errors)},model:{value:(_vm.dataSendForm.code),callback:function ($$v) {_vm.$set(_vm.dataSendForm, "code", $$v)},expression:"dataSendForm.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"code-class"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Học viên tối đa "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Học viên tối đa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code-class","type":"number","placeholder":"Học viên tối đa","state":_vm.getElementState(errors)},model:{value:(_vm.dataSendForm.maxQty),callback:function ($$v) {_vm.$set(_vm.dataSendForm, "maxQty", $$v)},expression:"dataSendForm.maxQty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trạng thái "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trạng thái","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"status","reduce":function (option) { return option.value; },"options":_vm.optionsStatus},model:{value:(_vm.dataSendForm.status),callback:function ($$v) {_vm.$set(_vm.dataSendForm, "status", $$v)},expression:"dataSendForm.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Kiểu phòng học","label-for":"status"}},[_c('v-select',{attrs:{"id":"status","reduce":function (option) { return option.value; },"options":_vm.optRoomTypes},model:{value:(_vm.dataSendForm.roomTypeId),callback:function ($$v) {_vm.$set(_vm.dataSendForm, "roomTypeId", $$v)},expression:"dataSendForm.roomTypeId"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"course"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tòa nhà "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tòa nhà","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"status","reduce":function (option) { return option.value; },"options":_vm.optBuildings},model:{value:(_vm.dataSendForm.buildingId),callback:function ($$v) {_vm.$set(_vm.dataSendForm, "buildingId", $$v)},expression:"dataSendForm.buildingId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Số thự tự","label-for":"orderNo"}},[_c('b-form-input',{attrs:{"id":"status"},model:{value:(_vm.dataSendForm.orderNo),callback:function ($$v) {_vm.$set(_vm.dataSendForm, "orderNo", $$v)},expression:"dataSendForm.orderNo"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-left"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid},on:{"click":_vm.handleSaveData}},[_vm._v(" Lưu ")])],1)])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }