<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-form>
      <b-row>
        <!-- Name Building -->
        <b-col cols="12">
          <b-form-group label-for="name-class">
            <template v-slot:label>
              Tên phòng <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tên phòng"
              rules="required"
            >
              <b-form-input
                id="name-room"
                v-model="dataSendForm.name"
                name="nameRoom"
                placeholder="Tên phòng"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Code -->
        <b-col cols="6">
          <b-form-group label-for="code-class">
            <template v-slot:label>
              Mã phòng <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Mã phòng"
              rules="required"
            >
              <b-form-input
                id="code-class"
                v-model="dataSendForm.code"
                type="text"
                placeholder="Mã phòng"
                :disabled="disableCode"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-col>

        <b-col cols="6">
          <b-form-group label-for="code-class">
            <template v-slot:label>
              Học viên tối đa <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Học viên tối đa"
              rules="required"
            >
              <b-form-input
                id="code-class"
                v-model="dataSendForm.maxQty"
                type="number"
                placeholder="Học viên tối đa"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- trạng thái -->
        <b-col cols="6">
          <b-form-group label-for="status">
            <template v-slot:label>
              Trạng thái <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
            >
              <v-select
                id="status"
                v-model="dataSendForm.status"
                :reduce="option => option.value"
                :options="optionsStatus"
              />
              <b-form-invalid-feedback :state="getElementState(errors)">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Kiểu phòng học"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="dataSendForm.roomTypeId"
              :reduce="option => option.value"
              :options="optRoomTypes"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label-for="course">
            <template v-slot:label>
              Tòa nhà <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tòa nhà"
              rules="required"
            >
              <v-select
                id="status"
                v-model="dataSendForm.buildingId"
                :reduce="option => option.value"
                :options="optBuildings"
              />
              <b-form-invalid-feedback :state="getElementState(errors)">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Created by -->
        <b-col cols="6">
          <b-form-group
            label="Số thự tự"
            label-for="orderNo"
          >
            <b-form-input
              id="status"
              v-model="dataSendForm.orderNo"
            />
          </b-form-group>
        </b-col>

        <!-- Created at -->

        <!-- submit and reset -->
        <b-col cols="12">
          <div class="float-left">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BRow, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { STATUSES } from '@/const/status'
import { ROOM_TYPES } from '@/const/type'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'FormRooms',
  components: {
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  props: {
    dataSendForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsStatus: [],
      optRoomTypes: [],
      optBuildings: [],
      disableCode: true,
      required,
    }
  },
  computed: {
    ...mapGetters({
      buildings: 'building/buildings',
    }),
  },
  async created() {
    // init data FROM API
    this.optionsStatus = STATUSES
    this.optRoomTypes = ROOM_TYPES
    if (!this.dataSendForm.id) {
      this.disableCode = false
    }
    await this.getAllBuildings()
    this.optBuildings = this.buildings.map(item => ({ value: item.buildingId, label: item.buildingName }))
    if (!this.dataSendForm.buildingId) {
      this.dataSendForm.buildingId = this.optBuildings[0].value
    }
    if (!this.dataSendForm.status) {
      this.dataSendForm.status = this.optionsStatus[1].value
    }
    if (!this.dataSendForm.roomTypeId) {
      this.dataSendForm.roomTypeId = this.optRoomTypes[0].value
    }
  },
  methods: {
    ...mapActions({
      getBuildings: 'building/getBuildings',
    }),
    // handle even
    handleSaveData(e) {
      this.$emit('saveDataForm', this.dataSendForm)
      e.preventDefault()
    },

    async getAllBuildings() {
      this.items = []
      await this.getBuildings({
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: 1,
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped>

</style>
